.connecting-dialog{
    background-color: white;
    display: grid;
    height: 200px;
    justify-content: center;
    align-items: center;
    width: 280px;
    margin: 10px;
    border-radius: 5px;
}

.connecting-dialog-overlay{
    background: rgba(150, 150, 150, 0.5);
    height: 100%;
    padding-top: 150px;
    position: fixed;
    top: 0;
    z-index: 1000;
}

.cancel-button-view{
    display: flex;
    justify-content: center;
}

.cancel-button-view button{
    width: 180px; 
    box-shadow: 0px 2px 5px grey;
    text-transform: none;
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 4px;
}

.asheetlogo svg{
    height: 60px;
    width: 50px;
}
.connecting-text{
    font-size: 14px;
    margin-top: 20px;
}
.arrow-icon-connecting{
    margin: 1px 11px;
    margin-top: 11px;
}
