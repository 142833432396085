@import "./../../../styles/variables.scss";

.column-selection-header-container{
    padding: 8px;
    display: flex;
    justify-content: space-between;
    height: 10%;
}

.column-selection-header-container-notfordialog{
    padding: 8px;
    display: flex;
    justify-content: space-between;
    height: 10%;
}

.columns-selection-header-name-div {
    margin-block: auto;
    margin-top: 5px;
    width: 130%;
  
    div {
      display: grid;
    }
  
    span {
      color: $secondary;
      font-size: 18px;
  
      span {
        font-weight: 700;
      }
    }

    label {
      color: $secondary;
      font-weight: $font-weight-bold;
      font-size: 22px;
      font-family: "Poppins";
    }
}

.column-flex-div{
    width: 100%;
    display: flex;
}

.column-header-name-container{
    display: flex;
    column-gap: 10px;
    width: 50%;
}

.column-header-part-one{
    width: 50%;
    display: flex;
}
.icon-image-div{
    width: 70px;
    height: 70px;

    img{
        height: 50px;
        margin-top: 10px;
        margin-left: 7px;
    }
}
.content-div{
    width: 100%;
    margin-left:10px;
}

.content-div-flex{
    width: 100%;
    display: flex;
}

.column-header-name{
    display: flex;
    column-gap: 7px;
    margin-top: 3px;
    font-size: 17px;
    width: 96%;
}


.connector-name-label{
    color: $secondary !important;
    font-weight: $font-weight-bold !important;
    font-size: 16px !important;
    font-family: "Poppins" !important;
}

.connector-name-label .MuiAutocomplete-inputRoot {
    width: auto !important;
  }

.change-div-container{
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    font-size: 14px;
}

.addscript-div-container{
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    font-size: 14px;
    margin:0px 15px 11px 10px;
}

.line-btw-column-header-content{
    border: 0.5px solid rgba(112, 112, 112, 0.2);
    height: 70%;
    margin-top: 4px;
}


.column-selection-header-btn-div{
    height: 30px;
    display: flex;
    margin-top: 15px;
    // justify-content: center;
    // flex-direction: column;
}

.filter-sort-btn-div{
    width: auto;
    display: flex;
    border: 1px solid #16365F;
    border-radius: 10px;
    margin-right: 20px;
}

.line-btw-column-filter-sort{
    border: 1px solid #16365F91;
    height: 99%;
}

.filter-div-header{
    position: relative;
    display: flex;
    cursor: pointer;

    label{
        color: $secondary;
        font-size: 13px;
        margin-top: 3px;
        cursor: pointer;
    }
    svg{
        font-size: 19px;
        margin-top: 3px;
        cursor: pointer;
    }
    img{
        height: 13px;
        width: 13px;
        margin-right: 4px;
        cursor: pointer;
    }
}

.selected-filter-sort{
    background-color: #16365F;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    label{
        color: white;
    }
}

.selected-sort{
    background-color: #16365F;
    label{
        color: white;
    }
}

.close-icon {
    // width: 25px; 
    // height: 20px;
    padding: 5px;
}

.close-popup-div{
    position: absolute;
    // padding: 2px;
    top: -25px;
    right: -32px;
    height: 35px !important;
    width: 35px !important;
    background: #ffffff;
    border-radius: 49%;
    cursor: pointer;
}

.native-columns-selection-header-name-div{
    margin-block: auto;
    margin-top: 20px;
    width: 130%;
  
    div {
      display: grid;
    }
  
    span {
      color: $secondary;
      font-size: 18px;
  
      span {
        font-weight: 700;
      }
    }

    label {
      color: $secondary;
      font-weight: $font-weight-bold;
      font-size: 22px;
      font-family: "Poppins";
    }
}