.flex {
  display: flex;
  align-items: center;
  justify-self: start;

  button {
    background-color: white !important;
    padding: 1px 4px !important;
    border: 1px solid gray;
    margin-left: 30px
  }
}

.border-bottom {
  height: 35px;
  width: 100%;
  border-bottom: 1px solid #80808061;
}

.source-header {
  display: flex;
  align-items: center;
  padding: 10px 0px !important;
  // cursor: pointer;

  span {
    font-size: 10px;
    font-style: italic;
    margin-left: 5px;
  }
}

.plugin-group-name {
  span {
    font-size: 13px !important;
    font-style: normal !important;
  }
}


.source-header img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.plugin-collections-info-icon {
  width: 16px !important;
  position: absolute;
  right: 10px;
}

.content div.active {
  display: grid;
  gap: 8px;
}

.connction-list {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
  // height: 49.5px;
  // cursor: pointer;

  svg {
    height: 12px;
    width: 12px;
  }

  img {
    height: 12px;
    width: 12px;
  }
}

.f-9 {
  font-size: 9.5px;
}

.plugin-menu-icon {
  position: absolute;
  visibility: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  

  svg {
    left: 14.4rem;
    position: relative;
    bottom: 1.7rem;
    padding-inline: 10px;
  }
}

.plugin-menu-margin {
  margin-top: 0.5rem;
}

.connction-list:hover {
  background: #00000017;
  cursor: pointer;
}

.connction-list:hover .plugin-menu-icon {
  display: inline-block;
  visibility: visible;
}

.add-new-connection-btn {

  button {
    margin: 30px !important;
    width: 80% !important;
    border-radius: 10px;
    height: 1.8rem;
  }
}

.plugin-menu-container {
  left: 186px !important;
  top: -40px !important;
}



.plugin-menu-container-data {
  position: relative !important;
  min-height: 30px !important;
  font-size: 12px !important;
  color: #16365F !important;
}

.plugin-collections-content {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.plugin-menu-container-data:hover {
  color: #16365F !important;
}

.not-linked-collection-margin {
  // margin-left: 1.25rem;
}

.plugin-dialog h2{
  font-size: 14px !important;
}