@import "../../styles/variables.scss";

.login-signup-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.as-form-spinner {
  width: -webkit-fill-available;
  height: 6px;
  background: linear-gradient(90deg, #0001 60%, #16365F 65%, #0001 70%) #e0cc4d;
  background-size: 300% 100%;
  animation: pos 1s infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px 10px 0px 0px;
}

.paper-container {
  display: flex !important;
}

.login-signup-container .columns {
  width: 50%;
  height: 100%;
  // display: flex;
  align-items: center;
  justify-content: center;
}

.login-signup-container .columns.column-1 {
  width: 50%;
}

.login-signup-container .columns.column-2 {
  width: 50%;
  overflow: auto;
  // display: block;
}

.login-signup-container .columns img {
  height: 85%;
  width: 100%;
  object-fit: cover;
}

.login-signup-container .columns .form-container {
  margin: 0 auto;
  margin-block-start: 3%;
  margin-block-end: 5%;
  height: fit-content;
  width: 60%;
  padding: 35px 4% 40px 4%;
  border-radius: 10px;
  box-shadow: $paper-box-shadow;
  position: relative;
}

.login-form-header {
  justify-content: space-between;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.login-form-header .header-item.header-item-1 {
  font-family: $poppins;
  font-size: 20px;
  color: $primary;
  font-weight: $font-weight-semibold;
}

.login-form-header .header-item.header-item-2 svg {
  // display: flex;
  // align-items: center;
  width: 160px;
}

.login-form-header .header-item.header-item-2 span {
  font-family: $poppins;
  margin: 0 0 1px 5px;
  font-size: 20px;
  color: #16365F;
  font-weight: $font-weight-bold;
  margin-left: 5px;
}

.login-form-inputs {
  padding: 0% 3% 2% 3%;
}

.forgot-password-link {
  float: right;
  color: $link-color !important;
  font-weight: 400 !important;
  font-size: 14px;
  text-transform: capitalize;
  text-decoration: none;
  text-align: end;
  display: inline-block;
}

.forgot-password-link:hover {
  cursor: pointer;
}

.forgot-password {
  margin-top: 2% !important;

  .error {
    font-size: 14px;
    padding: 6% 5%;
    margin-bottom: 3%;
    background-color: $block-background;
    color: $secondary;
    border-radius: 5px;
  }
}

.login-button-override {
  margin-top: 7% !important;
}

.login-button {
  text-align: center;
  margin-top: 3%;

  .link {
    color: $button-primary-text !important;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: capitalize;
    text-decoration: none;
    padding: 0%;
  }
}

.login-buttons-group {
  display: grid;
  gap: 10px;
  justify-items: center;
}

.login-button button {
  text-transform: capitalize;
  border-radius: 25px;
  padding: 2% 10%;
  font-weight: 600;
  background-color: $button-primary !important;

  &:hover {
    background-color: $button-primary-hover !important;
  }

  &:disabled {
    background-color: #c7d0d9 !important;
  }
}

.eye-icon {
  position: relative;
}

.eye-icon-override svg {
  top: 20px !important;
}

.eye-icon svg {
  color: rgba(0, 0, 0, 0.6) !important;
  position: absolute;
  top: 30px;
  right: 15px;

  &:hover {
    cursor: help;
  }
}

.footer {
  border-top: 1px solid $border-secondary;
  margin: 5% 3% 0% 3%;
  position: relative;
  bottom: 0;
  padding-top: 3%;
  color: $secondary;
  font-size: 14px;
}

.footer .footer-text {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 4%;

  .footer-link {
    width: fit-content;
    padding: 0;

    label {
      cursor: pointer !important;
    }
  }
}

.social-login-div {
  display: flex;
  align-items: center;
  margin-top: 4%;
  gap: 12px;

  a img {
    width: 25px !important;
    height: 25px !important;
    object-fit: contain !important;
  }
}

.footer-text label {
  color: #6b859e;
}

.login-signup-link {
  color: $link-color !important;
  font-weight: 400 !important;
  font-size: 14px;
  text-decoration: none;
}

.business-radio-div {
  color: rgba(0, 0, 0, 0.6);
}

.login-signup-link:hover {
  cursor: pointer;
}

.footer>label {
  position: absolute;
  width: 43px;
  background-color: $white;
  left: 50%;
  top: -12px;
  transform: translateX(-50%);
  text-align: center;
}

.icon {
  height: 15px;
  width: 15px;
}

.icon svg:hover {
  cursor: pointer;
}

.or-label {
  color: #16365F;
}

.invalid-field {
  color: red;
  font-size: 12px;
  display: block;
  margin-top: -10px;
}

@keyframes animateNewDivEle {
  0% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }

  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animated-div {
  animation: 0.6s animateNewDivEle;
}

.password-text-field {
  margin-top: 5px !important;
  width: 100%;
}

.plugin-view {
  position: absolute !important;
  left: 0;
  right: 0;
  width: auto !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.privacy-policy-div {
  position: absolute;
  display: flex;
  bottom: 4%;
  left: 42%;
  right: 42%;
  gap: 20px;
  font-family: $poppins;
  a {
    text-decoration: none;
    color: $primary;
    font-family: $poppins;
  }
} 

.privacy-policy-div-iframe {
  position: absolute;
  display: flex;
  font-size: 12px;
  left: 20%;
  gap: 20px;
  font-family: $poppins;
  margin-top: 10px;
  a {
    text-decoration: none;
    color: $primary;
    font-family: $poppins;
  }
}