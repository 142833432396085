/** @format */

@import "../../styles/variables.scss";

.header-username {
  color: $secondary;
  padding-left: 15px;
  bottom: -3px !important;
  left: 0px !important;
  text-transform: capitalize;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.avatar-grid {
  display: flex;
  padding-right: 10px;
  justify-content: flex-end;
  align-items: center;
  margin-top: -2px !important;
}

.header-username-hover {
  color: $secondary;
  padding-left: 15px;
  bottom: 0px !important;
  left: 0px !important;
}

.header-username-hover:hover {
  cursor: pointer;
}

.organisation {
  color: $secondary;
}

.organisation-name {
  display: flex;
  align-items: center;
  height: 22px;
  color: $secondary;
  font-size: 16px !important;
  font-weight: 400 !important;
  :hover {
    cursor: pointer;
  }
}

.organisation-name-div {
  display: flex;
  align-items: center;
}

.organisation-name-div svg:hover {
  cursor: pointer;
  // transform: rotateY(0deg) rotate(180deg);
  // transition: transform 1s;
}

.iFrame-option{
  font-size: 14px !important;
  margin-right: 10px !important;
  color: #00f;
  cursor: pointer;
  margin-top: 5px !important;
}