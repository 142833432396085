.source-header {
    display: flex;
    align-items: start !important;
    padding: 10px 10px;
    cursor: pointer;

    span {
        font-size: 10px;
        font-style: italic;
        margin-left: 5px;
    }
}

.source-header img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.info-icon {
    display: none;
    width: 16px !important;
    position: absolute;
    right: 10px;
}

.source-header:hover .info-icon {
    display: inline-block;
}

.pluginContainer {
    width: 100vw;
    background-color: #ffffff;
    color: #16365f;
    font-size: 13px !important;
}

.tabs {
    font-size: 11px;
    display: flex;
    justify-content: flex-start;
    gap: 4px;
    align-items: center;
    margin-top: 5px;
    margin-left: 5px;
}

.tab {
    padding: 5px 5px;
    cursor: pointer;
}

.tab.active {
    font-weight: bold;
    background-color: #f9fbe8;
    border: 1px dashed gray;
}

.connect-new-btn {
    margin-right: 5px;
    color: #4d53e0;
    cursor: pointer;
}

//
.content div.active {
    display: grid;
    gap: 0px !important;
}

.circularLoader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -25px; /* half of the size */
    margin-left: -25px;

}

.plugin-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #80808061;

    svg {
        height: 36px;
        // padding-left: 50px;
        // margin-bottom: 2.5px;
    }

    button {
        border: none;
        background: none;
        color: inherit;
        padding: 0px;
        margin: 0px;
    }
}

.indented {
    margin-left: 20px;
}

.subgroup {
    font-size: 0.9em;
    margin-top: 0.5rem;
}


.error-div {
    font-size: 16px;
    padding: 2% 5%;
    margin: 4% 0;
    color: #6b859e;
    background-color: #ffffff;
    border-radius: 5px;
  }

  .custom-link {
    cursor: pointer;
    font-weight: 500;
  }