.fetching-data-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0%;
    z-index: 1;
  }

  .fetching-data-content {
    color: #16365F;
    background: white;
    padding: 21px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }

  .fetching-data-icons {
    display: flex;
    align-items: baseline;
    justify-content: center;

    img {
        height: 46px;
        width: 46px;
        margin: 0 2.5px;
    }

    span {
        svg {
            height: 62px;
            width: 46px;
            margin: 0 4px;
        }
    }
  }